import type { AxiosInstance } from 'axios'
import type { GetInteractionCountOfAFeatureByOrganizationResponse } from '~/types/apiResponse/featureFlag.response'

export enum FeatureCodes {
  EMAIL_INVOICE = 'EMAIL_INVOICE',
  ACKNOWLEDGE_ONBOARDING_COMPLETED = 'ACKNOWLEDGE_ONBOARDING_COMPLETED',
  ACKNOWLEDGE_ONBOARDING_REJECTED = 'ACKNOWLEDGE_ONBOARDING_REJECTED',
}

export const featureFlag = (axiosInstance: AxiosInstance) => {
  return {
    getInteractionCountOfAFeatureByOrganization(
      organisationId: string,
      featureCode: FeatureCodes,
    ) {
      return axiosInstance.get<GetInteractionCountOfAFeatureByOrganizationResponse>(
        `/featureFlag/interactions`,
        { params: { orgId: organisationId, featureCode } },
      )
    },

    checkIfOrgHasAccessToFeature(
      organisationId: string,
      featureCode: FeatureCodes,
    ) {
      return axiosInstance.get<{ is_success: boolean; result: boolean }>(
        '/featureFlag/access',
        {
          params: { orgId: organisationId, featureCode },
        },
      )
    },
    trackFeatureUsage(payload: {
      organisationId: string
      featureCode: FeatureCodes
    }) {
      const { organisationId, featureCode } = payload
      return axiosInstance.post('/featureFlag/usagetracking', null, {
        params: {
          orgId: organisationId,
          featureCode,
        },
      })
    },
  }
}
