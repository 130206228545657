import type { AxiosInstance } from 'axios'
import type { SendInvitationPayload } from '~/types/apiPayload/invitations.payload'
import type {
  GetInvitationsForEmailResponse,
  GetInvitationsResponse,
} from '~/types/apiResponse/invitations.response'

export const invitations = (axiosInstance: AxiosInstance) => {
  return {
    getInvitations(
      organisationId: string,
      personId: string,
      excludeAcceptedInvitations: boolean,
    ) {
      return axiosInstance.get<GetInvitationsResponse>('/invitations', {
        params: { organisationId, personId, excludeAcceptedInvitations },
      })
    },

    sendInvitation(payload: SendInvitationPayload) {
      const { organisation_id } = payload
      return axiosInstance.post(
        `/invitations/v2/organisation/${organisation_id}`,
        { ...payload },
      )
    },

    getInvitationsForAnEmail(email: string) {
      return axiosInstance.get<GetInvitationsForEmailResponse>(
        `/invitations/Email/${email}`,
      )
    },

    declineInvitation(invitationId: string) {
      return axiosInstance.post(`/Invitations/${invitationId}/decline`)
    },

    acceptInvitation(invitationId: string) {
      return axiosInstance.post(`/invitations/${invitationId}/accept`)
    },
  }
}
