/* eslint-disable no-console */
import axios, { AxiosError, type AxiosInstance } from 'axios'
import dayjs, { unix } from 'dayjs'
import { useAuthService } from './auth'
import {
  addErrorMetadataToBugsnagEventForNetworkError,
  buildErrorStringForBugsnagForNetworkRequests,
  logout,
} from '~/lib/utils'
import { useAuthStore } from '~/stores/auth'

async function _checkAndRenew() {
  const authStore = useAuthStore()
  const { authUser } = authStore

  if (authUser && authUser.expires_at) {
    const authService = useAuthService()
    const now = dayjs() // Current time
    const expirationTime = unix(authUser.expires_at) // Token expiration time
    const EXPIRATION_THRESHOLD_MINUTES = 1 // Threshold for renewal

    const diff = expirationTime.diff(now, 'minute')

    if (diff < EXPIRATION_THRESHOLD_MINUTES) {
      try {
        const newUser = await authService.renewToken()
        if (newUser) {
          authStore.setUpUserCredentials(newUser)
        }
      } catch (error) {
        console.error('Error renewing token', error)
      }
    }
  } else {
    console.error('No user or no expiry date')
  }
}

const endpointsToSkipErrorReporting: Record<
  string,
  Record<number | string, [number]>
> = {
  GET: {
    // '/some-endpoint': [404],
    // '/another-endpoint': [404, 500],
  },
  POST: {
    '/CreditFacilities/request-fund/organisation/': [404],
  },
}

function shouldSkipReportingToBugsnag(
  endpoint: string,
  method: string,
  statusCode: number,
): boolean {
  const methodEndpoints = endpointsToSkipErrorReporting[method]

  if (methodEndpoints) {
    for (const pattern in methodEndpoints) {
      if (endpoint.startsWith(pattern)) {
        const statusCodes = methodEndpoints[pattern]
        return statusCodes.includes(statusCode)
      }
    }
  }

  return false
}

export default function useApiService() {
  const config = useRuntimeConfig()

  function createAxiosInstance(baseURL: string): AxiosInstance {
    const instance = axios.create({
      baseURL,
    })

    instance.interceptors.request.use(
      (config) => {
        const { accessToken } = useAuthStore()
        const { organisationId } = storeToRefs(useProfileStore())
        const token = accessToken
        config.headers.Authorization = `Bearer ${token}`
        if (organisationId.value) {
          config.headers['x-org-id'] = organisationId.value
        }
        return config
      },
      (error: AxiosError) => {
        const { $event } = useNuxtApp()
        $event('error:report', error)
        return Promise.reject(error)
      },
    )

    instance.interceptors.response.use(
      function (response) {
        if (response.status === 401) {
          logout()
        }

        return response
      },
      function (error: AxiosError) {
        if (error?.response?.status === 401) {
          logout()
        } else {
          if (
            !shouldSkipReportingToBugsnag(
              error.response?.config.url || '',
              error?.config?.method?.toUpperCase() || '',
              error.status || 0,
            )
          ) {
            const { $bugsnag } = useNuxtApp()
            const { profile, currentlySelectedOrganisation } =
              storeToRefs(useProfileStore())
            const { personId } = storeToRefs(useAuthStore())
            $bugsnag.notify(
              new Error(
                buildErrorStringForBugsnagForNetworkRequests({
                  error,
                  organisation: currentlySelectedOrganisation.value,
                  personId: personId.value?.toString(),
                  profile: profile.value,
                }),
              ),
              (event) => {
                event.addMetadata(
                  'payload and error',
                  addErrorMetadataToBugsnagEventForNetworkError(error),
                )
              },
            )
          }
          return Promise.reject(error)
        }
      },
    )

    return instance
  }

  const lenkieApi = createAxiosInstance(config.public.LENKIE_API_URL || '')
  const lenkieCompositionApi = createAxiosInstance(
    config.public.LENKIE_COMPOSITION_API_URL || '',
  )
  const lenkieBankingApi = createAxiosInstance(
    config.public.LENKIE_BANK_URL || '',
  )
  const rutterApi = createAxiosInstance(config.public.RUTTER_API || '')
  const openBankingApi = createAxiosInstance(
    config.public.OPEN_BANKING_API_URL || '',
  )
  const financeApi = createAxiosInstance(config.public.CASH_ADVANCE_API || '')
  const stripeApi = createAxiosInstance(config.public.STRIPE_API_URL || '')
  const backMarketApi = createAxiosInstance(
    config.public.BACK_MARKET_API_URL || '',
  )
  const analyticsApi = createAxiosInstance(
    config.public.LENKIE_ANALYTICS_URL || '',
  )

  const ocrApiInstance = createAxiosInstance(config.public.OCR_BASE_URL || '')

  const goCardLessApiInstance = createAxiosInstance(
    config.public.GO_CARDLESS_API_URL || '',
  )

  const coreApiAxiosInstance = createAxiosInstance(config.public.LENKIE_API_URL)

  const compositionApiAxiosInstance = createAxiosInstance(
    config.public.LENKIE_COMPOSITION_API_URL,
  )

  const stsApiAxiosInstance = createAxiosInstance(config.public.AUTH_URL)

  const underwritingApiAxiosInstance = createAxiosInstance(
    config.public.UNDERWRITING_URL,
  )

  return {
    lenkieApi,
    rutterApi,
    backMarketApi,
    lenkieBankingApi,
    openBankingApi,
    financeApi,
    lenkieCompositionApi,
    stripeApi,
    analyticsApi,
    coreApiAxiosInstance,
    ocrApiInstance,
    compositionApiAxiosInstance,
    stsApiAxiosInstance,
    goCardLessApiInstance,
    underwritingApiAxiosInstance,
  }
}
