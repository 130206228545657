import { toast } from '~/components/ui/toast'
import { FeatureCodes } from '~/network/core/featureFlag'
import type { GetInteractionCountOfAFeatureByOrganizationResponse } from '~/types/apiResponse/featureFlag.response'

type FeatureAccessMap = Partial<Record<FeatureCodes, boolean>>
type FeatureInteractionsMap = Partial<
  Record<
    FeatureCodes,
    GetInteractionCountOfAFeatureByOrganizationResponse['result']
  >
>

export const useFeatureFlagStore = defineStore('featureFlag', () => {
  const { $api } = useNuxtApp()
  const { organisationId } = storeToRefs(useProfileStore())
  const featureAccessStatus = ref<FeatureAccessMap>({})
  const featureInteractionCounts = ref<FeatureInteractionsMap>({})
  const hasFetchedFeatureFlagsData = ref(false)

  const interactionCountsMap = computed(() => {
    const keys = Object.keys(featureInteractionCounts.value) as FeatureCodes[]
    return keys.reduce(
      (accm, next) => {
        accm[next] = featureInteractionCounts.value[next]?.tracker_count
        return accm
      },
      {} as Partial<Record<FeatureCodes, number>>,
    )
  })

  const hasOnboardingAcknowledged = computed(() => {
    const completedCount =
      interactionCountsMap.value.ACKNOWLEDGE_ONBOARDING_COMPLETED || 0
    const rejectedCount =
      interactionCountsMap.value.ACKNOWLEDGE_ONBOARDING_REJECTED || 0

    return completedCount > 0 || rejectedCount > 0
  })

  // Function to fetch feature access for given codes
  async function fetchFeatureAccess(
    featureCodes: FeatureCodes[],
  ): Promise<Record<FeatureCodes, boolean>> {
    const accessCheckPromises = featureCodes.map((code) =>
      $api.core.featureFlag.checkIfOrgHasAccessToFeature(
        organisationId.value!,
        code,
      ),
    )

    const accessResponses = await Promise.all(accessCheckPromises)

    return featureCodes.reduce(
      (results, code, index) => {
        results[code] = accessResponses[index]?.data?.result || false
        return results
      },
      {} as Record<FeatureCodes, boolean>,
    )
  }

  // Function to fetch feature interaction counts for given codes
  async function fetchFeatureInteractions(
    featureCodes: FeatureCodes[],
  ): Promise<
    Record<
      FeatureCodes,
      GetInteractionCountOfAFeatureByOrganizationResponse['result']
    >
  > {
    const interactionCountPromises = featureCodes.map((code) =>
      $api.core.featureFlag.getInteractionCountOfAFeatureByOrganization(
        organisationId.value!,
        code,
      ),
    )

    const interactionCountResponses = await Promise.all(
      interactionCountPromises,
    )

    return featureCodes.reduce(
      (accm, next, index) => {
        accm[next] = interactionCountResponses[index]?.data?.result
        return accm
      },
      {} as Record<
        FeatureCodes,
        GetInteractionCountOfAFeatureByOrganizationResponse['result']
      >,
    )
  }

  async function initializeFeatureFlags() {
    try {
      // Define the feature codes to check
      const featureCodes = [
        FeatureCodes.ACKNOWLEDGE_ONBOARDING_COMPLETED,
        FeatureCodes.ACKNOWLEDGE_ONBOARDING_REJECTED,
        // FeatureCodes.EMAIL_INVOICE,
      ]

      // Fetch access and interaction counts
      const [accessResults, interactionResults] = await Promise.all([
        fetchFeatureAccess(featureCodes),
        fetchFeatureInteractions(featureCodes),
      ])

      // Update store with fetched results
      featureAccessStatus.value = {
        ...featureAccessStatus.value,
        ...accessResults,
      }

      featureInteractionCounts.value = {
        ...featureInteractionCounts.value,
        ...interactionResults,
      }

      hasFetchedFeatureFlagsData.value = true
    } catch (error) {
      toast({
        title: 'Error occured',
        description: 'Error occured while fetching feature flags data',
      })
      // Handle errors that occur during API calls
      // console.error('Failed to initialize feature flags:', error)
    }
  }

  return {
    featureAccessStatus,
    featureInteractionCounts,
    interactionCountsMap,
    hasOnboardingAcknowledged,
    hasFetchedFeatureFlagsData,
    initializeFeatureFlags,
  }
})
