import type { AxiosInstance } from 'axios'
import type { ScreenOrganisatonForCreditPayload } from '~/types/apiPayload/screening.payload'
import type { ScreenOrganisatonForCreditResponse } from '~/types/apiResponse/screening.response'

export const screening = (axiosInstance: AxiosInstance) => {
  return {
    screenOrganisationForCredit(payload: ScreenOrganisatonForCreditPayload) {
      return axiosInstance.post<ScreenOrganisatonForCreditResponse>(
        `/screening`,
        {
          ...payload,
        },
      )
    },
  }
}
