import type { AxiosInstance } from 'axios'
import type {
  BillChartDataType,
  BillSummaryType,
  Invoice,
  PossibleDuplicates,
} from '~/shared/interfaces'
import type {
  ExportBillsRequestParams,
  ArchiveBillPayload,
  GetBillsQueryParams,
  FindDuplicateBillPayload,
  GetBillPaymentHistoryByMonthParams,
  MakeBulkBillPaymentPayload,
  MakeSingleBillPaymentPayload,
  ValidateBillPaymentPayload,
} from '~/types/apiPayload/bills.payload'
import type {
  ApproveOrRejectBillPayload,
  BillRequestApprovalPayload,
  GetBillsResponse,
  ImportBillsInBulkResponse,
  MakeBulkPaymentResponse,
  ValidateBillPaymentResponse,
} from '~/types/apiResponse/bills.response'

export const bills = (axiosInstance: AxiosInstance) => {
  return {
    getBillById(billId: string) {
      return axiosInstance.get<{ result: Invoice }>(`/bills/v2/${billId}`)
    },

    getAllBills(params: GetBillsQueryParams = {}) {
      if (params.Statuses && params.Statuses.length) {
        const queryParams = params.Statuses.map(
          (status) => `Statuses=${status}`,
        ).join('&')
        const otherParams = Object.entries(params)
          .filter(([key]) => key !== 'Statuses')
          .map(([key, value]) => {
            if (value && value !== null) {
              return `${key}=${value}`
            } else {
              return ''
            }
          })
          .join('&')

        const queryString = `${queryParams}${otherParams ? `&${otherParams}` : ''}`

        return axiosInstance.get<GetBillsResponse>(`/Bills/v2?${queryString}`)
      } else {
        return axiosInstance.get<GetBillsResponse>('/Bills/v2', {
          params,
        })
      }
    },

    exportBills(payload: {
      organisationId: string
      params?: ExportBillsRequestParams
    }) {
      const { organisationId, params } = payload
      return axiosInstance.post(
        `Bills/export/${organisationId}`,
        {
          ...params,
        },
        { responseType: 'blob' },
      )
    },

    archiveBill(payload: ArchiveBillPayload) {
      return axiosInstance.post('/bills/archive', { ...payload })
    },

    findBillDuplicates(payload: FindDuplicateBillPayload) {
      return axiosInstance.post<PossibleDuplicates[]>('/Bills/duplicates', {
        ...payload,
      })
    },

    getTotalBillsSummary(organisationId: string) {
      return axiosInstance.get<BillSummaryType>(
        `/Bills/Summary/${organisationId}`,
      )
    },

    getBillPaymentHistoryByMonth(params: GetBillPaymentHistoryByMonthParams) {
      return axiosInstance.get<BillChartDataType[]>(
        '/bills/payment-summary-group-by-date',
        {
          params: { ...params },
        },
      )
    },

    makeSingleBillPayment(payload: MakeSingleBillPaymentPayload) {
      return axiosInstance.post('/Bills/PayBill', payload)
    },

    makeBulkBillPayment(payload: MakeBulkBillPaymentPayload) {
      return axiosInstance.post<MakeBulkPaymentResponse>(
        '/Bills/bulk/pay',
        payload,
      )
    },

    downloadTemplateForBulkBills(fileType: 'Csv' | 'Excel') {
      return axiosInstance.get('/Bills/template', {
        params: { fileType },
        responseType: 'blob',
      })
    },

    importBillsInBulk(payload: { organisationId: string; file: File }) {
      const { organisationId, file } = payload
      const formData = new FormData()
      formData.append('bills', file)

      return axiosInstance.post<ImportBillsInBulkResponse>(
        `/bills/organisation/${organisationId}/import`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      )
    },
    forceSyncAccountingConnection(organisationId: string) {
      const orgId = organisationId
      return axiosInstance.get(`/bills/force-sync-accounting/${orgId}`)
    },
    requestApproval(payload: BillRequestApprovalPayload) {
      return axiosInstance.post('/bills/request-approval', payload)
    },
    approveOrRejectBill(payload: ApproveOrRejectBillPayload) {
      return axiosInstance.post('/bills/approve-reject-bill', payload)
    },
    acknowledgeFailedSyncBills(bill_ids: string[]) {
      return axiosInstance.post('/bills/accounting/acknowledge-failed-sync', {
        bill_ids,
      })
    },
    validateBillPayment: (payload: ValidateBillPaymentPayload) => {
      return axiosInstance.post<ValidateBillPaymentResponse>(
        '/bills/validate-payment',
        payload,
      )
    },
  }
}
