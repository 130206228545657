import type { AxiosInstance } from 'axios'
import type { CreateProfileForLoggedInUserPayload } from '~/types/apiPayload/me.payload'

export const me = (axiosInstance: AxiosInstance) => {
  return {
    createProfileForLoggedInUser(payload: CreateProfileForLoggedInUserPayload) {
      return axiosInstance.post('/Me', { ...payload })
    },
  }
}
